<template>
  <v-footer id="dashboard-core-footer">
    <v-container>
        <div class="body-1 font-weight-light text-center">
          &copy; Seamless Source - {{ new Date().getFullYear() }} - All Rights
          Reserved
        </div>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "DashboardCoreFooter",
};
</script>

<style lang="sass">
#dashboard-core-footer
  a
    font-size: .825rem
    font-weight: 500
    text-decoration: none
    text-transform: uppercase
</style>
